<template>
    <v-dialog
        v-model="dialog"
        max-width="1000"
        persistent
    >
        <v-form
            ref="signosForm"
            @submit="agrega"
            onSubmit="return false;"
            v-model="validaSigno"
        >
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    Agregar signos
                </v-card-title>

                <v-card-text class="pt-5">

                    {{moment(moment(estaFecha, "DD-MM-YYYY")).format("dddd MMMM DD, YYYY")}}<br/>
                    <b>Hora a registrar: </b> {{ calculaHoraCarga(horaCarga) }}<br/>
                    <v-row no-gutters> 
                        <v-col xs="6" sm="6" md="6" lg="6" xl="6" cols="12">
                            <v-form v-model="muestraCampos">
                                <v-select
                                    class="mt-3"
                                    v-model="horaCarga"
                                    :items="desactivarOpcionSignos()"
                                    :rules="[
                                        value => !!value || 'Requerido.'
                                    ]"
                                    label="Periodo de registro"
                                    rounded
                                    outlined
                                    dense
                                >
                                </v-select>
                            </v-form>
                        </v-col>
                    </v-row>

                    <v-expand-transition>
                        <div v-if="muestraCampos" no-gutters>

                            <v-sheet
                                elevation="2"
                                class="mb-2"
                            >
                                <v-toolbar class="headline grey lighten-2 mb-2" dense flat>
                                    <v-toolbar-title>
                                        Signos y contantes
                                    </v-toolbar-title>
                                </v-toolbar>
                                <v-row no-gutters>
                                    <v-col
                                        class="px-1"
                                        v-for="(item, i) in signosListSomato"
                                        :key="i"
                                        xs="6"
                                        sm="6"
                                        md="4"
                                        lg="3"
                                        xl="4"
                                        cols="12"
                                    >

                                        <!-- Para los datos numéricos -->
                                        <v-text-field
                                            v-if="!camposTa(item.signo)"
                                            v-model="datosSignos[item.signo]"
                                            :disabled="evaluaSigno(item.signo)"
                                            :persistent-hint="evaluaSigno(item.signo)"
                                            :hint='evaluaSigno(item.signo)?"Existe registro a esta hora":""'
                                            :rules="[
                                                value => !value || (value >= item.min || 'Valor MENOR al mínimo ('+item.min+')'),
                                                value => !value || (value <= item.max || 'Valor MAYOR al máximo ('+item.max+')'),
                                            ]"
                                            type="number"
                                            filled
                                            rounded
                                            clearable
                                            dense
                                            :label="item.nombreLargo"
                                        ></v-text-field>

                                        <!-- para los datos de texto como la TA -->
                                        <v-text-field
                                            v-if="camposTa(item.signo)"
                                            :persistent-hint="evaluaSigno(item.signo)"
                                            :hint='evaluaSigno(item.signo)?"Existe registro a esta hora":""'
                                            :disabled="evaluaSigno(item.signo)"
                                            v-model="datosSignos[item.signo]"
                                            filled
                                            rounded
                                            clearable
                                            dense
                                            :label="item.nombreLargo"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </div>
                    </v-expand-transition>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        v-if="muestraCampos"
                        color="success"
                        type="submit"
                        :disabled="!validaSigno"
                        :loading="loadingSigno"
                    >
                        Agregar
                    </v-btn>

                    <v-btn color="error" @click="cancelaAgrega">
                        Cancelar
                    </v-btn>
                </v-card-actions>


            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
export default {
    props:{
        estaFecha: String,
        dialogSignosTodos: Boolean,
        internamiento:Number,
        item: Object,
    },
    data: () => ({
        validaSigno:false,
        loadingSigno:false,
        periodoCarga:'',
        horaCarga: 1,
        mismaHora:[],
        muestraCampos:false,
        todo:false,
        datosSignos:{
            //
        },
        intervalosSignos: [
            {text:'Antes de iniciar', value: 1, disabled: false },
            {text:'A los 15 minutos', value: 2, disabled: false },
            {text:'1 hora', value: 3, disabled: false },
            {text:'2 horas', value: 4, disabled: false },
            {text:'3 horas', value: 5, disabled: false },
            {text:'termino de la transfusion', value: 6, disabled: false }
        ],
        signosList:[
            {signo: 'temp',min: 33,max: 43,nombreLargo:'Temperatura (ºC)', tipo:'s'},
            {signo: 'fc',min: 20,max: 220,nombreLargo:"Frec. cardiaca (X')", tipo:'s'},
            {signo: 'fr',min: 5,max: 220,nombreLargo:"Frec. resp. (X')", tipo:'s'},

            {signo: 'ta',min: 0,max: 100,nombreLargo:"T. Artetial (mmHg)", tipo:'s'},
        ],
    }),
    methods:{

        desactivarOpcionSignos(){
            if(this.item.terminada === true){
                this.intervalosSignos[5].disabled = false
                return this.intervalosSignos
            }
            else{
                this.intervalosSignos[5].disabled = true
                return this.intervalosSignos
            }
        },
        aumentaTiempo(hora, horas, minutos){
            const horaSeparada = hora.split(':')
            var nuevaHora = Number(horaSeparada[0]) + horas;
            var nuevosMinutos = Number(horaSeparada[1]) + minutos;
            nuevosMinutos = nuevosMinutos.toString().padStart(2, '0')
            return `${nuevaHora}:${nuevosMinutos}`
        },
        calculaHoraCarga(opcionHora){
            switch(opcionHora){
                case 1:{
                    console.log(this.item.horaInicio)
                    return this.item.horaInicio
                }
                case 2:
                    return this.aumentaTiempo(this.item.horaInicio, 0, 15)
                case 3:
                    return this.aumentaTiempo(this.item.horaInicio, 1, 0)
                case 4:
                    return this.aumentaTiempo(this.item.horaInicio, 2, 0)
                case 5:
                    return this.aumentaTiempo(this.item.horaInicio, 3, 0)
                case 6:{
                    const fechaActual = new Date(this.item.fechaTermino)
                    return `${fechaActual.getHours() + 1}:${fechaActual.getMinutes()}`
                }
            }
        },
        async agrega(){
            // ------------------------------------------------------------------------------
            // Utiliza async await con un for para agregar varios registros secuencialmente
            // ------------------------------------------------------------------------------
            let dt
            this.loadingSigno = true

            for(dt of Object.entries(this.datosSignos)){
                let ds = {
                    hora:this.calculaHoraCarga(this.horaCarga),
                    fecha:this.estaFecha,
                    // Revisar como podemos jalar este desde una propiedad
                    internamiento:this.internamiento,
                    signo:dt[0],
                    valor:dt[1],
                }

                if (dt[1]) {
                    try{
                        let sg = await this.$http({
                            url:'/enfermeria/guardaSignos',
                            method:'POST',
                            data: ds
                        })
                        if (sg.data.estado == false){
                            this.loadingSigno = false
                            this.$swal.fire({
                                icon: 'warning',
                                title: 'No se puede guardar',
                                text: 'Verifique los datos e intente de nuevo',
                            })
                            this.todo = false
                            break

                        }
                    } catch(err){
                        this.loadingSigno = false
                        this.todo = false
                        console.log(err)
                        this.$store.dispatch('security/revisaError',err)
                        break
                    }
                }
                this.todo = true
            }
            this.loadingSigno = false

            if (this.todo == true){
                this.$refs.signosForm.reset()
                this.horaCarga=''
                this.$emit('signoAgregado')
                this.dialog=false
            }
        },
        camposTa(val){
            //console.log(val == 'ta' || val == 'taIn')
            return (val == 'ta' || val == 'taIn' || val == 'tipov')
        },
        cancelaAgrega(){
            this.dialog=false
            this.horaCarga=''
            this.$refs.signosForm.reset()
        },
        evaluaSigno(signo){
            return (this.mismaHora.includes(signo))
        },
    },
    computed:{
        dialog:{
            get(){
                return this.dialogSignosTodos
            },
            set(value){
                //console.log(value)
                this.$emit('update:dialogSignosTodos',value)
            },
        },

        signosListSomato(){
            return (this.signosList.filter(ss=>ss.tipo=='s'))
        }
    },
    watch:{
        'datosSignos.ta'(val){
            let expresion = /\d["/"]\d/
            if(expresion.test(val)){
                let taArray = val.split("/")
                let taS = parseInt(taArray[0])
                let taD = parseInt(taArray[1])
                if (taS > taD){
                    //this.datosSignos.tam=this.$numeral((taS+(taD*2))/3).format('0.00')
                    this.datosSignos.tam=Math.round((taS+(taD*2))/3)
                }
            }
        },
        'datosSignos.taIn'(val){
            let expresion = /\d["/"]\d/
            if(expresion.test(val)){
                let taArray = val.split("/")
                let taS = parseInt(taArray[0])
                let taD = parseInt(taArray[1])
                if (taS > taD){
                    //this.datosSignos.tam=this.$numeral((taS+(taD*2))/3).format('0.00')
                    this.datosSignos.taInM=Math.round((taS+(taD*2))/3)
                }
            }
        }
    },

}
</script>

<style>

</style>